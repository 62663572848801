import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Topographic,
  Layout,
  Hero,
  About,
  Featured,
  Projects,
  Contact
} from '@components'

const StyledMainContainer = styled.main`
  counter-reset: section;
`

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Topographic />
    <StyledMainContainer className="fillHeight">
      <Hero />
      <About />
      <Featured />
      <Projects />
      <Contact />
    </StyledMainContainer>
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired
}

console.log("%c ",
"display: inline-block ; background-image: url( 'https://static1.squarespace.com/static/6037c9fa956ba770b5e719c4/t/6165d33cd6afc90e1aae35d2/1634063165351/cowboy-man.jpeg' ) ; " +
  "background-size: cover ; padding: 10px 175px 158px 10px ; " +
  "border: 2px solid black ; font-size: 11px ; line-height: 11px ; " +
  "font-family: monospace ;"
  );
console.log(
"%cWell howdy there, pard'ner! Didn't see ya there! If you're reading this, welcome to the Netherworld! Lookin' for a site or maybe even a job? Check us out at wearetiny.io!",
"color: cyan; font-family:courier; font-size: 15px"
);      

export default IndexPage
